<template>
  <div id="app"></div>
</template>

<script>
import * as webglinput from "./webglinput.js"
import * as apple from "./apple.js"
import * as google from "./google.js"
import * as facebook from "./facebook.js"
import * as metamask from "./metamask.js"
import * as walletconnect from "./walletconnect.js"
import * as torus from "./torus.js"
import * as paypalpay from "./paypalpay.js"
import * as tracker from "./tracker.js"
import * as resource from "./system.js"
import * as system from "./system.js"
import * as unity from "./unity.js"
import * as line from "./line.js"
import * as binance from "./binance.js"
import * as fractal from "./fractal.js"
import * as carv from "./carv.js"
import * as coinbase from "./coinbase.js"
import * as coin98wallet from "./coin98.js"
import * as facewallet from "./facewallet.js"
import * as alipay from "./alipay.js"
import * as toss from "./toss.js"
export default {
  name: 'App',
  created() {
    window['app'] = this;
    window['unity'] = unity;
    window['WebGLInput'] = webglinput;
    window['apple'] = apple;
    window['google'] = google;
    window['facebook'] = facebook;
    window['walletconnect'] = walletconnect;
    window['metamask'] = metamask;
    window['torus'] = torus;
    window['paypalpay'] = paypalpay;
    window['tracker'] = tracker;
    window['resource'] = system;
    window['system'] = system;
    window['line'] = line;
    window['binance'] = binance;
    window['fractal'] = fractal;
    window['carv'] = carv;
    window['coinbase'] = coinbase;
    window['coin98wallet'] = coin98wallet;
    window['facewallet'] = facewallet;
    window['alipay'] = alipay;
    window['toss'] = toss;
    window.NativeRequest = async function (command, param) {
      try {
        var actions = command.split(".");
        if(actions[0]=="card")
          actions[0] = "paypalpay";
        else if(actions[0]=="coin98")
          actions[0] = "coin98wallet";
        await window[actions[0]][actions[1]](param);
      } catch (e) {
        console.log(e);
      }
    }
    setTimeout(async () => {
      window.pushId = await window.OneSignal.getUserId();
    }, 10);
  },
  methods: {
    connect: async function () {
    }
  }
}
</script>

<style>
</style>
