var pkgCosts = [0,"0.99","4.99","9.99","19.99","49.99","99.99"];
export function event(data) {
    try{
        var splitter = data.indexOf(":");
        var name = data;
        var param = {};
        if(splitter>=0)
        {
            name = data.substr(0, splitter);
            param = JSON.parse(data.substr(splitter+1));
        }
        if (window.firebase != null)
            window.firebase.analytics().logEvent(name, param);
        if (window.fbq != null)
            window.fbq('track', name, param);
        if(window.gtag!=null)
            window.gtag('event', name, param);
        if(window._persona!=null)
            window._persona.Attribution.track(name, param);

        if(name=="purchase")
        {
            var value = pkgCosts[Number(param.costCode)||0];
            var purchaseParam = {product_id:param.sku,currency:"USD",value:value,quantity:"1"};
            if(window.firebase!=null)
                window.firebase.analytics().logEvent("in_app_purchase", purchaseParam);
            if(window.fbq!=null)
                window.fbq('track', 'Purchase', purchaseParam);
            if(window.gtag!=null)
                window.gtag('event', "in_app_purchase", purchaseParam);
        }

    }catch(e){
        console.log(e);
    }
}
export function property(param) {
    try{
        if (typeof param === 'string' || param instanceof String)
            param = JSON.parse(param);
        if (window.firebase != null)
            window.firebase.analytics().setUserProperties(param);
    }catch(e){
        console.log(e);
    }
}