window.onfile = function (e) {
  var reader = new FileReader();
  var file = e.target.files[0];
  reader.onloadend = function () {
    window.unity.response(reader.result);
  }
  reader.readAsDataURL(file);
}
var over = document.getElementById("over")
var frame = document.getElementById("frame")
export async function init(){
  await new Promise(r => setTimeout(r, 500)); 
  window.unity.init();
}
export function update() {
  window.location.reload();
}
export function gallery() {
  document.getElementById("file").click();
}
export function link(url) {
  window.open(url, '_blank').focus();
}
export function show(url) {
  over.style.display = "block";
  frame.src = url;
}
export function hide() {
  over.style.display = "none";
  frame.src = "";
}
export function webresponse(token) {
  over.style.display = "none";
  frame.src = "";
  window.unity.response(token);
}
export function copy(value){
  var textarea = document.createElement('textarea');
  textarea.value = value;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 9999);
  document.execCommand('copy');
  document.body.removeChild(textarea);
}
export function share(value){

}