import * as util from "./util.js"
async function init() {
    if (window.FB != null)
        return;
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: '898956330585949',
            cookie: true,                     // Enable cookies to allow the server to access the session.
            xfbml: true,                     // Parse social plugins on this webpage.
            version: 'v14.0'           // Use this Graph API version for this call.
        });
    };
    await util.loadScript("https://connect.facebook.net/en_US/sdk.js");
}

function fbStatus() {
    return new Promise((resolve) => {
        window.FB.getLoginStatus(resolve);
    });
}

function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
}
function fbLogin() {
    return new Promise((resolve, reject) => {
        try {
            window.FB.login(function (response) {
                resolve(response);
            }, {
                scope: 'email,public_profile',
                return_scopes: true
            });
        } catch (e) {
            reject(e);
        }
    });
}
export async function login() {
    await init();
    await sleep(1000);
    var response;
    // response = await fbStatus();
    // if (response.status === 'connected')
    //     return window.unity.response(response.authResponse.accessToken);
    response = await fbLogin();
    if (response.status === 'connected')
        return window.unity.response(response.authResponse.accessToken);
}
export async function share(data) {
    let parse = data.split("^");
    window.FB.ui({
        display: 'popup',
        method: 'share',
        href: parse[0],
        quote: parse[1],
        hashtag: parse[2],
    }, function (response) { });
}
export async function logout() {
    await init();
    await sleep(1000);
    window.FB.logout(response => {
    });
}