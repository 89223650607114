var element = document.getElementById("WebGLInput");
var mode = 0; //0:chat, 1:mail

export function show(value) {
    element.value = value;
    element.focus();
}
export function hide(){
    element.blur();
}
export function selectionStart() {
    return element.selectionStart;
}
export function selectionEnd() {
    return element.selectionEnd
}
export function setSelection(start, end) {
    var val = element.value
    var len = val.length + 1;

    if (len < start || len < end) return;
        element.focus()
        if (element.setSelectionRange) {
            element.setSelectionRange(start, end);
        }
}
export function isActive() {
    return (element.style.display != 'none');
}
export function submit() {
    if (mode != 0)
        return;
    window.unity.send('PopupChat', 'OnClickSend');
    element.value = "";
}
export function value() {
    return element.value;
}
export function setMode(m) {
    mode = Number(m);
}