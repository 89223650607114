let PKG_NAMES = {
    pkg_: "League of Kingdoms Package",
    "pkg_80100101":"Glowing Crystals 1",
"pkg_80100102":"Glowing Crystals 2",
"pkg_80100103":"Glowing Crystals 3",
"pkg_80100104":"Glowing Crystals 4",
"pkg_80100105":"Glowing Crystals 5",
"pkg_80100106":"Glowing Crystals 6",
"pkg_80100107":"Glowing Crystals 7",
"pkg_80100108":"Glowing Crystals 8",
"pkg_80200101":"Remaking of the kingdoms 1",
"pkg_80200102":"Remaking of the kingdoms 2",
"pkg_80200103":"Remaking of the kingdoms 3",
"pkg_80200104":"Remaking of the kingdoms 4",
"pkg_80200105":"Remaking of the kingdoms 5",
"pkg_80200201":"Technological development 1",
"pkg_80200202":"Technological development 2",
"pkg_80200203":"Technological development 3",
"pkg_80200204":"Technological development 4",
"pkg_80200205":"Technological development 5",
"pkg_80200301":"Wealthy Mighty Kingdom 1",
"pkg_80200302":"Wealthy Mighty Kingdom 2",
"pkg_80200303":"Wealthy Mighty Kingdom 3",
"pkg_80200304":"Wealthy Mighty Kingdom 4",
"pkg_80200305":"Wealthy Mighty Kingdom 5",
"pkg_80200401":"Abundant resources 1",
"pkg_80200402":"Abundant resources 2",
"pkg_80200403":"Abundant resources 3",
"pkg_80200404":"Abundant resources 4",
"pkg_80200405":"Abundant resources 5",
"pkg_80200501":"Treasure chest 1",
"pkg_80200502":"Treasure chest 2",
"pkg_80200503":"Treasure chest 3",
"pkg_80200504":"Treasure chest 4",
"pkg_80200505":"Treasure chest 5",
"pkg_80200601":"Ready for battle 1",
"pkg_80200602":"Ready for battle 2",
"pkg_80200603":"Ready for battle 3",
"pkg_80200604":"Ready for battle 4",
"pkg_80200605":"Ready for battle 5",
"pkg_80200701":"Flourishing kingdom 1",
"pkg_80200702":"Flourishing kingdom 2",
"pkg_80200703":"Flourishing kingdom 3",
"pkg_80200704":"Flourishing kingdom 4",
"pkg_80200705":"Flourishing kingdom 5",
"pkg_80200801":"The Dragon raid 1",
"pkg_80200802":"The Dragon raid 2",
"pkg_80200803":"The Dragon raid 3",
"pkg_80200804":"The Dragon raid 4",
"pkg_80200805":"The Dragon raid 5",
"pkg_80200901":"The Dragon raid 1",
"pkg_80200902":"The Dragon raid 2",
"pkg_80200903":"The Dragon raid 3",
"pkg_80200904":"The Dragon raid 4",
"pkg_80200905":"The Dragon raid 5",
"pkg_80201001":"The Dragon raid 1",
"pkg_80201002":"The Dragon raid 2",
"pkg_80201003":"The Dragon raid 3",
"pkg_80201004":"The Dragon raid 4",
"pkg_80201005":"The Dragon raid 5",
"pkg_80201101":"The skin pack 1",
"pkg_80201102":"The skin pack 2",
"pkg_80201103":"The skin pack 3",
"pkg_80201104":"The skin pack 4",
"pkg_80201105":"The skin pack 5",
"pkg_80201201":"Black Friday Package 1",
"pkg_80201202":"Black Friday Package 2",
"pkg_80201203":"Black Friday Package 3",
"pkg_80201204":"Black Friday Package 4",
"pkg_80201205":"Black Friday Package 5",
"pkg_80201301":"The battlefield package 1",
"pkg_80201302":"The battlefield package 2",
"pkg_80201303":"The battlefield package 3",
"pkg_80201304":"The battlefield package 4",
"pkg_80201305":"The battlefield package 5",
"pkg_80201401":"Colossal Vigor 1",
"pkg_80201402":"Colossal Vigor 2",
"pkg_80201403":"Colossal Vigor 3",
"pkg_80201404":"Colossal Vigor 4",
"pkg_80201405":"Colossal Vigor 5",
"pkg_80201501":"The Settler package 1",
"pkg_80201502":"The Settler package 2",
"pkg_80201503":"The Settler package 3",
"pkg_80201504":"The Settler package 4",
"pkg_80201505":"The Settler package 5",
"pkg_80201601":"Age of exploration 1",
"pkg_80201602":"Age of exploration 2",
"pkg_80201603":"Age of exploration 3",
"pkg_80201604":"Age of exploration 4",
"pkg_80201605":"Age of exploration 5",
"pkg_80201701":"Peace to the new world 1",
"pkg_80201702":"Peace to the new world 2",
"pkg_80201703":"Peace to the new world 3",
"pkg_80201704":"Peace to the new world 4",
"pkg_80201705":"Peace to the new world 5",
"pkg_80201801":"Ready for expedition 1",
"pkg_80201802":"Ready for expedition 2",
"pkg_80201803":"Ready for expedition 3",
"pkg_80201804":"Ready for expedition 4",
"pkg_80201805":"Ready for expedition 5",
"pkg_80201901":"The new hegemon 1",
"pkg_80201902":"The new hegemon 2",
"pkg_80201903":"The new hegemon 3",
"pkg_80201904":"The new hegemon 4",
"pkg_80201905":"The new hegemon 5",
"pkg_80202001":"Thanksgiving Package 1",
"pkg_80202002":"Thanksgiving Package 2",
"pkg_80202003":"Thanksgiving Package 3",
"pkg_80202004":"Thanksgiving Package 4",
"pkg_80202005":"Thanksgiving Package 5",
"pkg_80202101":"Prosperous kingdom 1",
"pkg_80202102":"Prosperous kingdom 2",
"pkg_80202103":"Prosperous kingdom 3",
"pkg_80202104":"Prosperous kingdom 4",
"pkg_80202105":"Prosperous kingdom 5",
"pkg_80202201":"Technological breakthrough 1",
"pkg_80202202":"Technological breakthrough 2",
"pkg_80202203":"Technological breakthrough 3",
"pkg_80202204":"Technological breakthrough 4",
"pkg_80202205":"Technological breakthrough 5",
"pkg_80202301":"Arms Race 1",
"pkg_80202302":"Arms Race 2",
"pkg_80202303":"Arms Race 3",
"pkg_80202304":"Arms Race 4",
"pkg_80202305":"Arms Race 5",
"pkg_80202401":"Overflowing cornucopia 1",
"pkg_80202402":"Overflowing cornucopia 2",
"pkg_80202403":"Overflowing cornucopia 3",
"pkg_80202404":"Overflowing cornucopia 4",
"pkg_80202405":"Overflowing cornucopia 5",
"pkg_80202501":"Treasure Hall 1",
"pkg_80202502":"Treasure Hall 2",
"pkg_80202503":"Treasure Hall 3",
"pkg_80202504":"Treasure Hall 4",
"pkg_80202505":"Treasure Hall 5",
"pkg_80202601":"Prepare the Raid 1",
"pkg_80202602":"Prepare the Raid 2",
"pkg_80202603":"Prepare the Raid 3",
"pkg_80202604":"Prepare the Raid 4",
"pkg_80202605":"Prepare the Raid 5",
"pkg_80300101":"30days Crystal supply",
"pkg_80300201":"7days Speed up",
"pkg_80300301":"7days of Food supply",
"pkg_80300401":"7days of Lumber supply",
"pkg_80300501":"7days of Stone supply",
"pkg_80300601":"7days of Gold supply",
"pkg_80300701":"7-Day Vitality",
"pkg_80300801":"30days Speed up",
"pkg_80300901":"30days of Food supply",
"pkg_80301001":"30days of Lumber supply",
"pkg_80301101":"30days of Stone supply",
"pkg_80301201":"30days of Gold supply",
"pkg_80301301":"30-Day Vitality",
"pkg_80400101":"Monthly Package 1",
"pkg_80400102":"Monthly Package 2",
"pkg_80400103":"Monthly Package 3",
"pkg_80400201":"Weekly Package",
"pkg_80400202":"Weekly Package",
"pkg_80400203":"Weekly Package",
"pkg_80400204":"Weekly Package",
"pkg_80400205":"Weekly Package",
"pkg_80400301":"Free Package",
"pkg_80400302":"Free Package",
"pkg_80400303":"Free Package",
"pkg_80400401":"Prosperous kingdom 1",
"pkg_80400402":"Prosperous kingdom 2",
"pkg_80400403":"Prosperous kingdom 3",
"pkg_80400404":"Prosperous kingdom 4",
"pkg_80400405":"Prosperous kingdom 5",
"pkg_80400501":"Technological breakthrough 1",
"pkg_80400502":"Technological breakthrough 2",
"pkg_80400503":"Technological breakthrough 3",
"pkg_80400504":"Technological breakthrough 4",
"pkg_80400505":"Technological breakthrough 5",
"pkg_80400601":"Arms Race 1",
"pkg_80400602":"Arms Race 2",
"pkg_80400603":"Arms Race 3",
"pkg_80400604":"Arms Race 4",
"pkg_80400605":"Arms Race 5",
"pkg_80400701":"Overflowing cornucopia 1",
"pkg_80400702":"Overflowing cornucopia 2",
"pkg_80400703":"Overflowing cornucopia 3",
"pkg_80400704":"Overflowing cornucopia 4",
"pkg_80400705":"Overflowing cornucopia 5",
"pkg_80400801":"Treasure Hall 1",
"pkg_80400802":"Treasure Hall 2",
"pkg_80400803":"Treasure Hall 3",
"pkg_80400804":"Treasure Hall 4",
"pkg_80400805":"Treasure Hall 5",
"pkg_80400901":"Prepare the Raid 1",
"pkg_80400902":"Prepare the Raid 2",
"pkg_80400903":"Prepare the Raid 3",
"pkg_80400904":"Prepare the Raid 4",
"pkg_80400905":"Prepare the Raid 5",
"pkg_80600101":"VIP1 Package",
"pkg_80600102":"VIP2 Package",
"pkg_80600103":"VIP3 Package",
"pkg_80600104":"VIP4 Package",
"pkg_80600105":"VIP5 Package",
"pkg_80600106":"VIP6 Package",
"pkg_80600107":"VIP7 Package",
"pkg_80600108":"VIP8 Package",
"pkg_80600109":"VIP9 Package",
"pkg_80600110":"VIP10 Package",
"pkg_80600111":"VIP11 Package",
"pkg_80600112":"VIP12 Package",
"pkg_80600113":"VIP13 Package",
"pkg_80600114":"VIP14 Package",
"pkg_80600115":"VIP15 Package",
"pkg_80800101":"The Last Chance",
"pkg_80800201":"The return of the king",
"pkg_80800301":"Honor of the Kingdom 1",
"pkg_80800302":"Honor of the Kingdom 2",
"pkg_80800303":"Honor of the Kingdom 3",
"pkg_80800304":"Honor of the Kingdom 4",
"pkg_80800305":"Honor of the Kingdom 5",
"pkg_80800306":"Honor of the Kingdom 6",
"pkg_80800401":"Legendary Weapon 1",
"pkg_80800402":"Legendary Weapon 2",
"pkg_80800403":"Legendary Weapon 3",
"pkg_80800501":"Return to the battlefield 1",
"pkg_80800502":"Return to the battlefield 2",
"pkg_80800503":"Return to the battlefield 3",
"pkg_80800601":"Halloween fest package",
"pkg_80800701":"Premium speedup package 1",
"pkg_80800702":"Premium speedup package 2",
"pkg_80800703":"Premium speedup package 3",
"pkg_80800801":"Premium resource package 1",
"pkg_80800802":"Premium resource package 2",
"pkg_80800803":"Premium resource package 3",
"pkg_80800901":"Premium training package 1",
"pkg_80800902":"Premium training package 2",
"pkg_80800903":"Premium training package 3",
"pkg_80801001":"Premium treasure package 1",
"pkg_80801002":"Premium treasure package 2",
"pkg_80801003":"Premium treasure package 3",
"pkg_80801101":"Avengers 1",
"pkg_80801102":"Avengers 2",
"pkg_80801103":"Avengers 3",
"pkg_80801201":"Legendary Weapon 1",
"pkg_80801202":"Legendary Weapon 2",
"pkg_80801203":"Legendary Weapon 3",
"pkg_80801301":"Return to the battlefield 1",
"pkg_80801302":"Return to the battlefield 2",
"pkg_80801303":"Return to the battlefield 3",
"pkg_80801401":"Premium package 1",
"pkg_80801402":"Premium package 2",
"pkg_80801403":"Premium package 3",
"pkg_80801501":"The Apprentice's crate",
"pkg_80801601":"Christmas package",
"pkg_80801701":"The dawn of heroes 1",
"pkg_80801702":"The dawn of heroes 2",
"pkg_80801703":"The dawn of heroes 3",
"pkg_80801704":"The dawn of heroes 4",
"pkg_80801705":"The dawn of heroes 5",
"pkg_80801706":"The dawn of heroes 6",
"pkg_80801801":"clay package 1",
"pkg_80801802":"clay package 2",
"pkg_80801901":"The treasure hunt 1",
"pkg_80801902":"The treasure hunt 2",
"pkg_80801903":"The treasure hunt 3",
"pkg_80802001":"The New Year Package",
"pkg_80802101":"anniversary package(middle east)",
"pkg_80802201":"anniversary package",
"pkg_80802301":"Black Friday Package",
"pkg_80802401":"valentine's day Package",
"pkg_80802501":"anniversary package",
"pkg_80802601":"Aegis Protection Package 1",
"pkg_80802602":"Aegis Protection Package 2",
"pkg_80802701":"The treasure hunt(mytic)",
"pkg_80802801":"Blue festival",
"pkg_80802901":"Invasion Preparation Package",
"pkg_80803001":"Giant Slayer Package",
"pkg_80803101":"The New Year Package(new)",
"pkg_80803201":"LootBox 1",
"pkg_80803202":"LootBox 2",
"pkg_80803203":"LootBox 3",
"pkg_80803204":"LootBox 4",
"pkg_80803205":"LootBox 5",
"pkg_80803206":"LootBox 6",
"pkg_80803301":"valentine's day Package",
"pkg_80803401":"anniversary package",
"pkg_80900101":"Premium package 1",
"pkg_80900102":"Premium package 2",
"pkg_80900103":"Premium package 3",
"pkg_80900104":"Premium package 4",
"pkg_80900201":"Premium package 1",
"pkg_80900202":"Premium package 2",
"pkg_80900203":"Premium package 3",
"pkg_80900204":"Premium package 4",
"pkg_81000101":"Staking bonus tier1",
"pkg_81000102":"Staking bonus tier2",
"pkg_81000103":"Staking bonus tier3",
"pkg_81000104":"Staking bonus tier4",
"pkg_81000105":"Staking bonus tier5",
"pkg_81000106":"Staking bonus tier6",
"pkg_81000107":"Staking bonus tier7",
"pkg_81000108":"Staking bonus tier8",
"pkg_81000109":"Staking bonus tier9",
"pkg_81100101":"Blue Crystals 1",
"pkg_81100102":"Blue Crystals 2",
"pkg_81100103":"Blue Crystals 3",
"pkg_81100104":"Blue Crystals 4",
"pkg_81100105":"Blue Crystals 5",
"pkg_81100106":"Blue Crystals 6",
};
const APP_NAME = 'League of Kingdoms'
const APP_LOGO_URL = 'https://leagueofkingdoms.com/img/logo_mini.png'

// const LOKPay = "0x42993fbf022C1822d639e680C762a3D1f0923b1d";//dev
// const LOKA_ADDRESS = "0xE65750382e372718bD634599CC6770D7E84eF5Ae"//dev;
const LOKPay = "0x48EC5f8C22658a2384017Cb5540DAF4A86963ec3";//prod
const LOKA_ADDRESS = "0x61E90A50137E1F645c9eF4a0d3A4f01477738406";//prod
const LOKA_ABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function",
      "constant": true
    }
  ];
//2. 변수, 함수를 하나의 변수에 담기!
const consts   = { PKG_NAMES, LOKA_ADDRESS, LOKA_ABI, LOKPay, APP_NAME, APP_LOGO_URL }

//3. 이 변수 내보내기! (반드시 해야함!)
export default consts;