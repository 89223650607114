var element = document.getElementById("alipay");
import * as consts from "./consts.js"
let names = consts.default.PKG_NAMES;

export async function buy(sku) {
  //sku = payCode + "^" + data.package.code + "^" + data.package.sku
  var ids = sku.split("^");
  var id = ids.length > 1?ids[1]:"";
  element.style.display = 'block';
  let params = new URLSearchParams();
  params.append("id", ids[0]);
  params.append("name", names["pkg_" + id]||id);
  params.append("cost", sku.split("_").pop());
  let url = "./toss/checkout.html?"+params.toString();
  window.open(url, "alipay");
}
export function onSuccess(token){
  window.unity.response(token)
  close();
}
export function close() {
  element.style.display = 'none';
}
export function cancel() {
  close();
  window.unity.response("cancel")
}