var initialized = false;
function resize() {
    send('App', 'InitCanvas');
}

var unityInstance = window.UnityLoader.instantiate("game", `Build/webgl.json?r=` + Math.random(), {
    onProgress: function(){},
    Module: {
        onRuntimeInitialized: onInit
    }
});
function onInit() {
    var splash = document.getElementById('splash');
    splash.classList = "game fadeout";
    splash.addEventListener('webkitAnimationEnd', function () {
        splash.remove();
    }, false);
}
window.onresize = function () {
    window.requestAnimationFrame(resize);
};
export function init() {
    initialized = true;
    send('App', 'InitApp', ["web", window.pushId, window.location.search].join('^'));
}
export function send(obj, method, value) {
    if (initialized)
        unityInstance.SendMessage(obj, method, value);
    console.log(initialized, obj, method, value);
}
export function response(value) {
    send('Core', 'Response', value);
}