import { Client, Chain } from '@coin98-com/connect-sdk';
import Web3 from 'web3';
import * as consts from "./consts.js";
let K = consts.default;
const WALLET_NAME = "coin98";
let ethereum;
let web3;
let client;
let LOKA;
async function init() {
  if(web3==null){
    ethereum = window.coin98?.provider;
    web3 = new Web3(ethereum);
  }
  if (web3 == null || ethereum == null) {
    alert(`Please connect ${WALLET_NAME}`);
    throw { code: `no_wallet` };
  }
  LOKA = new web3.eth.Contract(K.LOKA_ABI, K.LOKA_ADDRESS);
  if(client==null){
    client = new Client();
    client.connect(Chain.fantom, {
      logo: K.APP_LOGO_URL,
      name: K.APP_NAME,
      url: 'https://play.leagueofkingdoms.com'
    });
  }
  if (ethereum.selectedAddress != null&& ethereum.selectedAddress!="")
    return ethereum.selectedAddress;
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  if (accounts.length == 0) {
    alert(`No wallet in ${WALLET_NAME}`)
    throw { code: "no_wallet" };
  }
  await new Promise(r => setTimeout(r, 2000));
  return accounts[0];
}
export async function sign(data) {
  try {
    var account = await init();
    let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(data), account,"");
    window.unity.response(signature + "_" + account);
  } catch (e) {
    console.log(e);
    window.unity.response("cancel");
  }
}
export async function transaction(param) {
  let result = await ethereum.send('eth_sendTransaction', [param]);
  return result!=null?result.result:"";
}

let paydata = {
  approve: null,
  param: null,
  approve_tx_hash:null
}
export async function buy(param) {
  try {
    await send(param);
  } catch (e) {
    console.log(e);
    window.unity.response("cancel");
  }
}
export async function check(param) {
  let chain = await web3.eth.net.getId();
  if (param.chainId != chain) {
    window.unity.response("cancel");
    alert("Wrong Network - Please switch to the appropriate blockchain.")
    throw { code: "no_wallet" };
  }
  delete param.chainId;
  delete param.gas;
  delete param.nonce;
  delete param.gasLimit;
  delete param.gasPrice;
}
export async function approve() {
  try {
    let hash = await transaction(paydata.approve);
    paydata.approve_tx_hash = hash;
    setLoka(3);
  } catch (e) {
    console.log(e);
    cancel();
  }
}
export function setLoka(type) {
  window.document.getElementById("lokapay-container").style.backgroundImage = `url('./img/loka_0${type}.png')`;
  window.document.getElementById("approve-loka").style.display = type == 1 ? "block" : "none";
  window.document.getElementById("pay-loka").style.display = type == 2 ? "block" : "none";
  window.document.getElementById("waiting-loka").style.display = type == 3 ? "block" : "none";
  if(type==3)
    setTimeout(checkLoka, 1000);
}
export async function checkLoka()
{
  var receipt = await web3.eth.getTransactionReceipt(paydata.approve_tx_hash);
  if(receipt!=null&&receipt.status)
  {
    paydata.approve_tx_hash = null;
    setLoka(2);
  }
  else
    setLoka(3);
}
export async function cancel() {
  window.document.getElementById("lokapay").style.display = "none";
  window.unity.response("cancel");
}
export async function lokapay() {
  try {
    let param = paydata.param;
    await check(param);
    var hash = await transaction(param);
    window.document.getElementById("lokapay").style.display = "none";
    window.unity.response(hash);
  } catch (e) {
    console.log(e);
    cancel();
  }
}
export async function send(param) {
  try {
    if (typeof (param) == "string")
      param = JSON.parse(param);
    let account = await init();
    param.from = account;
    
    if (param.to.toLowerCase() == K.LOKPay.toLowerCase() && param.value == "0") {
      // paydata.approve = { "to": LOKA_ADDRESS, "data": "0x095ea7b300000000000000000000000042993fbf022C1822d639e680C762a3D1f0923b1d00000000000000000000000000000000000000000000d3c21bcecceda1000000", "value": "0", "chainId": 1, "from": account };
      paydata.approve = { "to": K.LOKA_ADDRESS, "data": "0x095ea7b300000000000000000000000048ec5f8c22658a2384017cb5540daf4a86963ec300000000000000000000000000000000000000000000d3c21bcecceda1000000", "value": "0", "chainId": 1, "from": account };
      paydata.param = param;
      window.document.getElementById("price-loka").innerText = param.price.toFixed(4);
      window.document.getElementById("pkgname").innerText = K.PKG_NAMES["pkg_"+param.pkgCode]||param.pkgCode;
      window.document.getElementById("loka2usd").innerText = param.loka2usd.toFixed(4);
      let allowance = await LOKA.methods.allowance(account, K.LOKPay).call();
      window.document.getElementById("lokapay").style.display = "block";
      console.log(allowance, allowance.toString());
      if (allowance.toString() == "0")
      {
        if(paydata.approve_tx_hash==null)
          setLoka(1);
        else
          setLoka(3);
      }
      else
        setLoka(2);
      return;
    }
    await check(param);
    var hash = await transaction(param);
    window.unity.response(hash);
  } catch (e) {
    console.log(e);
    cancel();
  }
} 