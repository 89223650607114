let url = "/facewallet/index.html"
export async function sign(data) {
  window.open(`${url}?chainId=1&type=sign&message=${data}`);
}
export async function buy(param) {
  try {
    await send(param);
  } catch (e) {
    console.log(e);
    window.unity.response("cancel");
  }
}
export async function send(param) {
  try {
    if (typeof (param) == "string")
      param = JSON.parse(param);
    window.open(`${url}?type=transaction&to=${param.to}&value=${param.value}&data=${param.data}&chainId=${param.chainId}`);
  } catch (e) {
    console.log(e);
    window.unity.response("cancel");
  }
}
export async function opensea(param) {
  try {
    window.open(`${url}?type=opensea`);
  } catch (e) {
    console.log(e);
  }
}
export function onhash(hash) {
  window.unity.response(hash);
}
export function onsign(data) {
  window.unity.response(data.toLowerCase());
}
export async function logout() {
}