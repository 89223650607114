let inited = false;
let popup;
// let CLIENT = "0a12b3414e279b5a2652604adfc47771904d866a";
let CLIENT = "ebaf17c68d5eadd103fea26d4f92a2c4bf61fdff";
let SCOPE = "carv_id_basic_read%20email_basic_read%20platform_detailed_read%20platform_detailed_write"
let STATE = 1014;
// let REDIRECT = "http://test-koc.nodgames.com.s3-website.ap-northeast-2.amazonaws.com/carv/callback.html";
let REDIRECT = "https://play.leagueofkingdoms.com/carv/callback.html";
let url = `https://auth.carv.io/auth/authorize?client_id=${CLIENT}&redirect_uri=${encodeURIComponent(REDIRECT)}&response_type=code&scope=${SCOPE}&state=${STATE}`;

export async function login() {
    popup = window.open(url, "carv");
}
export function onlogin(params){
    window.unity.response(params.code);
}
export async function logout() {
}