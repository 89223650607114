import * as util from "./util.js"
var gauth;
async function init() {
    if (gauth != null)
        return;
    await util.loadScript("https://apis.google.com/js/platform.js");
    await loadAuth();
}

function loadAuth() {
    return new Promise((resolve) => {
        window.gapi.load('auth2', resolve);
    });
}

export async function login() {
    await init();
    gauth = await window.gapi.auth2.init({
        client_id: '844727555882-1j7bt4qbkkniu3e4d6a5muqv4668p4la.apps.googleusercontent.com'
    });
    var options = new window.gapi.auth2.SigninOptionsBuilder();
    options.setPrompt('select_account');
    await gauth.signIn(options);
    window.unity.response(gauth.currentUser.get().getAuthResponse().id_token);
}

export async function logout() {
    await init();
    gauth.signOut();
}

//NOTE:바로 로그인시 활성화
// if (gauth.isSignedIn.get())
//     return window.unity.response(gauth.currentUser.get().getAuthResponse().id_token);