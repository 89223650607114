import Web3 from "web3";
import Torus from "@toruslabs/torus-embed";
import * as consts from "./consts.js"
let PKG_NAMES = consts.default.PKG_NAMES;
let web3;
let torus;
// const LOKPay = "0x42993fbf022C1822d639e680C762a3D1f0923b1d";//dev
// const LOKA_ADDRESS = "0xE65750382e372718bD634599CC6770D7E84eF5Ae"//dev;
const LOKPay = "0x48EC5f8C22658a2384017Cb5540DAF4A86963ec3";//prod
const LOKA_ADDRESS = "0x61E90A50137E1F645c9eF4a0d3A4f01477738406";//prod
let LOKA = {};
async function init() {
    torus = new Torus();
    await torus.init(); //{network: {host: "mainnet"}}//{network: {host: "rinkeby"}}
    await torus.login(); // await torus.ethereum.enable()
    web3 = new Web3(torus.provider);
    LOKA = new web3.eth.Contract([
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "spender",
              "type": "address"
            }
          ],
          "name": "allowance",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        },
        {
          "inputs": [
            {
              "internalType": "address",
              "name": "account",
              "type": "address"
            }
          ],
          "name": "balanceOf",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function",
          "constant": true
        }
      ], LOKA_ADDRESS);
    if(torus.ethereum.selectedAddress!=null)
        return torus.ethereum.selectedAddress;  
    return web3.eth.accounts.currentProvider.selectedAddress;
}
export async function sign(data) {
    try {
        let account = await init();
        await new Promise(r => setTimeout(r, 1000));
        let signature = await web3.eth.personal.sign(web3.utils.fromUtf8(data), account,"");
        window.unity.response(signature + "_" + account);
    } catch (e) {
        console.log(e);
        window.unity.response("cancel");
    }
}
export async function transaction(param) {
    var result = await web3.eth.sendTransaction(param);
    return result!=null?result.result:"";//?result.transactionHash?
}

let paydata = {
  approve: null,
  param: null,
  approve_tx_hash:null
}

export async function buy(param) {
  try {
    await send(param);
  } catch (e) {
    console.log(e);
    window.unity.response("cancel");
  }
}
export async function check(param) {
  let chain = await web3.eth.net.getId();
  if (param.chainId != chain) {
    window.unity.response("cancel");
    alert("Wrong Network - Please switch to the appropriate blockchain.")
    return;
  }
  delete param.chainId;
  delete param.gas;
  delete param.nonce;
  delete param.gasLimit;
  delete param.gasPrice;
}
export async function approve() {
  try {
    let hash = await transaction(paydata.approve);
    paydata.approve_tx_hash = hash;
    setLoka(3);
  } catch (e) {
    console.log(e);
    cancel();
  }
}
export function setLoka(type) {
  window.document.getElementById("lokapay-container").style.backgroundImage = `url('./img/loka_0${type}.png')`;
  window.document.getElementById("approve-loka").style.display = type == 1 ? "block" : "none";
  window.document.getElementById("pay-loka").style.display = type == 2 ? "block" : "none";
  window.document.getElementById("waiting-loka").style.display = type == 3 ? "block" : "none";
  if(type==3)
    setTimeout(checkLoka, 1000);
}
export async function checkLoka()
{
  var receipt = await web3.eth.getTransactionReceipt(paydata.approve_tx_hash);
  if(receipt!=null&&receipt.status)
  {
    paydata.approve_tx_hash = null;
    setLoka(2);
  }
  else
    setLoka(3);
}
export async function cancel() {
  window.document.getElementById("lokapay").style.display = "none";
  window.unity.response("cancel");
}
export async function lokapay() {
  try {
    let param = paydata.param;
    await check(param);
    var hash = await transaction(param);
    window.document.getElementById("lokapay").style.display = "none";
    window.unity.response(hash);
  } catch (e) {
    console.log(e);
    cancel();
  }
}
export async function send(param) {
  try {
    if (typeof (param) == "string")
      param = JSON.parse(param);
    let account = await init();
    param.from = account;
    
    if (param.to.toLowerCase() == LOKPay.toLowerCase() && param.value == "0") {
      // paydata.approve = { "to": LOKA_ADDRESS, "data": "0x095ea7b300000000000000000000000042993fbf022C1822d639e680C762a3D1f0923b1d00000000000000000000000000000000000000000000d3c21bcecceda1000000", "value": "0", "chainId": 1, "from": account };
      paydata.approve = { "to": LOKA_ADDRESS, "data": "0x095ea7b300000000000000000000000048ec5f8c22658a2384017cb5540daf4a86963ec300000000000000000000000000000000000000000000d3c21bcecceda1000000", "value": "0", "chainId": 1, "from": account };
      paydata.param = param;
      window.document.getElementById("price-loka").innerText = param.price.toFixed(4);
      window.document.getElementById("pkgname").innerText = PKG_NAMES["pkg_"+param.pkgCode]||param.pkgCode;
      window.document.getElementById("loka2usd").innerText = param.loka2usd.toFixed(4);
      let allowance = await LOKA.methods.allowance(account, LOKPay).call();
      window.document.getElementById("lokapay").style.display = "block";
      console.log(allowance, allowance.toString());
      if (allowance.toString() == "0")
      {
        if(paydata.approve_tx_hash==null)
          setLoka(1);
        else
          setLoka(3);
      }
      else
        setLoka(2);
      return;
    }
    await check(param);
    var hash = await transaction(param);
    window.unity.response(hash);
  } catch (e) {
    console.log(e);
    cancel();
  }
}