var element = document.getElementById("paypalpay");
var button = document.getElementById("paypal-button-container");
var pkgCosts = [0,"0.99","4.99","9.99","19.99","49.99","99.99","199.99","299.99","499.99"];
import * as consts from "./consts.js"
let names = consts.default.PKG_NAMES;
export async function buy(sku) {
  //sku = payCode + "^" + data.package.code + "^" + data.package.sku
  var costCode = Number(sku.split("_").pop());
  var ids = sku.split("^");
  var id = "";
  if(ids.length>1)
    id = ids[1];
  element.style.display = 'block';
  button.innerHTML = "";
  setTimeout(()=>{

    window.paypal.Buttons({
      createOrder: function (data, actions) {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [{
            custom_id : sku,
            description: names["pkg_"+id]||id,
            amount: {
              value: pkgCosts[costCode]
            },
          }],
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        });
      },
      onApprove: function (data, actions) {
        return actions.order.capture().then(function (details) {
          window.unity.response(details.purchase_units[0].payments.captures[0].id)
          window.paypalpay.close();
        });
      }
    }).render('#paypal-button-container');
  }, 10);
}
export function close() {
  element.style.display = 'none';
}
export function cancel(){
  element.style.display = 'none';
  window.unity.response("cancel")
}