var inited = false;
var returned = false;
var data = null;
let popup = null;
let isVerify = false;
const FRACTAL_DOMAIN_HTTPS = 'https://www.fractal.is';

function init(){
    if(inited)
        return;
    window.addEventListener('message', (event) => {
    if (event.origin !== FRACTAL_DOMAIN_HTTPS) {
        return;
    }
    console.log(event.data.event);
    if (event.data.event === 'HANDSHAKE') {
        popup.postMessage({ event: 'HANDSHAKE_ACK' }, event.origin);
    }

    if (event.data.event === 'POPUP_CLOSED') {
        if(returned)
            return;
        returned = true;
        window.unity.response("cancel");
    }

    if (event.data.event === 'PROJECT_APPROVED') {
        console.log(data.code);
        if(isVerify)
            window.unity.response(data.code+"_fractal");
        else
            window.unity.response(data.code);

        isVerify = false;
        returned = true;
    }
    });
    inited = true;
}
export async function sign(data) {
    isVerify = true;
    await login();
}
export async function login() {
    init();
    let url = `https://auth-api.fractal.is/auth/v2/approval/geturl?clientId=P5MqkhLM-W-LYXZNKf9R_UH8-J4jghlfhK2P4i44HUU&scope=items:read&scope=identify&scope=coins:read`;
    let result = await fetch(url);
    data = await result.json();
    popup = window.open(data.url, "fractal");
}
export async function logout() {
}